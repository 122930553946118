import { React, PropTypes } from '../../dependencies'
import { lastWord } from '../../utils'

const Contacts = ({ styles, persons }) => {
    // "Externí odkazy" last
    const sortedPersons = React.useMemo(
        () =>
            persons
                .map(person => person.node)
                .filter(person => Boolean(person.name))
                .sort((lhs, rhs) => lastWord(lhs.name).localeCompare(lastWord(rhs.name))), // Sort by last name
        [persons],
    )

    return (
        <ul className={styles.list}>
            {sortedPersons.map(person => (
                <li key={person.name} className={styles.person}>
                    <p className={styles.name}>{person.name}</p>
                    <a href={`tel:${person.phone}`}>{person.phone}</a>
                </li>
            ))}
        </ul>
    )
}

Contacts.propTypes = {
    styles: PropTypes.shape({
        list: PropTypes.string.isRequired,
    }).isRequired,
    persons: PropTypes.array,
}

export default Contacts
