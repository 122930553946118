export const list = () => ({
    marginTop: '2em',
    listStyle: 'none',
    padding: 0,
})

export const person = () => ({
    marginBottom: '1.5em',
})

export const name = () => ({
    marginBottom: '0.5em',
})
