import React from 'react'
import Layout from '../components/Layout'
import Contacts from '../components/Contacts'
import SEO from '../components/seo'
import { graphql } from 'gatsby'

const IndexPage = ({ data }) => (
    <Layout page="kontakt">
        <SEO title="Kontakt" />
        <a href={`mailto:${data.contentfulContact.email}`}>{data.contentfulContact.email}</a>
        <Contacts persons={data.allContentfulPerson.edges} />
    </Layout>
)

export const query = graphql`
    query ContactQuery {
        contentfulContact {
            email
        }
        allContentfulPerson(sort: { order: DESC, fields: name }, filter: { name: { nin: "Externí odkazy" } }) {
            edges {
                node {
                    name
                    phone
                }
            }
        }
    }
`

export default IndexPage
